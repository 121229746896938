<template>
  <div class="room-lobby-page bg-mid-black" v-if="showRoomLobby">
    <header class="page-header bg-black">
      <img src="@/assets/spacein_logo_white.svg" alt="" @click="goToHome">
    </header>
    <div class="room-lobby" v-if="!loadingInviteData && !isEnteringAfterAuth">
      <div class="room-lobby__left" :class="{joining: ifJoining&&ifJitsiRoom}">
        <div v-if="ifJoining && ifJitsiRoom" class="conference-test">
          <div class="info">
            <p>Allow SPACEIN to use your camera and microphone.</p>
            <p class="text-white">Preview your audio and video.</p>
          </div>
          <jitsi-preview />
        </div>
        <img v-else src="@/assets/spacein-intro.png" alt="about spacein" />
      </div>

      <div class="room-lobby__right">
        <div v-if="!validInvite" class="invalid">
          {{invalidMessage}}
        </div>
        <div v-else-if="isRejected" class="invalid">
          You are removed from the lobby
        </div>
        <div v-else-if="isKicked" class="invalid">
          You are removed from the room
        </div>
        <div v-else-if="ifJoining" class="join-room">
          <div class="header">
            <p>{{`${isWaiting ? 'Joining...' : 'Join'}`}}</p>
            <h3 class="room-name">{{roomName}}</h3>
          </div>

          <div v-if="isWaiting" class="waiting">
            <LoadingIcon />
            <p>Please hold on, the host <br v-if="!isPasswordRequired"> will let you in soon<span v-if="isPasswordRequired">,
              <br>or enter the password to join the Room now.</span>
            </p>
            <inputfield
              v-if="isPasswordRequired"
              large
              rounded
              placeholder="Type in Room's password"
              :customClasses="['bg-black', 'text-white', 'text-bold']"
              v-model="enteredPassword"
              @keypress.enter="joinRoom"
              :type="showPassword ? 'text' : 'password'"
            >
              <template #image>
                <img
                  class="text-white"
                  src="@/assets/eye-open.svg"
                  alt="show password"
                  v-if="showPassword && isWaiting"
                  @click="toggleShowPassword"
                />
                <img
                  class="text-white"
                  src="@/assets/eye.svg"
                  alt="show password"
                  v-else-if="isWaiting"
                  @click="toggleShowPassword"
                />
              </template>
            </inputfield>
            <div class="buttons">
              <btn v-if="isPasswordRequired" :disabled="!enteredPassword" @click="joinRoom" rounded wide large colour="green">
                <template #text>
                  <b>Join Room</b>
                </template>
              </btn>

              <btn @click="handleCancelJoinLobby" class="cancel" rounded wide large colour="ghost">
                <template #text>
                  <b>Cancel</b>
                </template>
              </btn>
            </div>
          </div>

          <div v-else-if="!ifAuthenticated" class="guest">
            <inputfield
              large
              rounded
              placeholder="Type in your name"
              :customClasses="['bg-black', 'text-white', 'text-bold']"
              v-model="guestName"
              @keypress.enter="joinRoom"
            >
            </inputfield>

            <div class="guest__terms" @click="toggleAgreeToTerms">
              <span class="guest__terms-checkbox"
              >
                <span class="checked bg-green" v-if="agreedToTerms"></span>
              </span>
              <small>
                I agree to SPACEIN
                <span @click.stop><a target="_blank" href="https://spacein.co.uk/terms-and-conditions" class="text-white">
                  <b>Terms & Conditions</b>
                </a></span>
                and
                <span @click.stop><a target="_blank" href="https://spacein.co.uk/privacy-policy" class="text-white">
                  <b>Privacy Policy.</b>
                </a>
                </span>
                </small>
            </div>

            <btn @click="joinRoom" :disabled="!guestName || !agreedToTerms" rounded wide large colour="green">
              <template #text>
                <b>Join Room</b>
              </template>
            </btn>

            <p class="already-member">Already a member? <span class="login" @click="goToLogin"><b>Login</b></span></p>
          </div>
          <div v-else class="current-user">
            <div class="user-details">
              <user-icon :currentUser="true" userStatus="offline" />
              <p class="full-name">{{fullName}}</p>
              <p class="email">{{email}}</p>
            </div>
            <div class="buttons">
              <btn class="logout" @click="onLogout" rounded wide large colour="ghost">
                <template #text>
                  <b>Logout</b>
                </template>
              </btn>
              <btn @click="joinRoom" rounded wide large colour="green">
                <template #text>
                  <b>Join Room</b>
                </template>
              </btn>
            </div>
          </div>
        </div>
        <div v-else class="left-room" :class="{authenticated: ifAuthenticated}">
          <div v-if="ifAuthenticated" class="user-details">
            <user-icon :currentUser="true" userStatus="offline" />
            <p class="full-name">{{fullName}}</p>
            <p class="email">{{email}}</p>
          </div>
          <p>You have left the Room.</p>

          <div class="buttons">
            <btn v-if="!isKicked" @click="reJoinRoom" rounded wide large colour="mauve">
              <template #text>
                <b>Rejoin</b>
              </template>
            </btn>

            <btn v-if="ifAuthenticated && !isExternalGuest" @click="goToHome" rounded wide large colour="yellow">
              <template #text>
                <b>Go to my Spaces</b>
              </template>
            </btn>
            <btn v-if="ifAuthenticated" @click="onLogout" class="logout" rounded wide large colour="ghost">
              <template #text>
                <b>Logout</b>
              </template>
            </btn>
          </div>
        </div>
        <div v-if="(!ifJoining && isExternalGuest) || !validInvite || isRejected" class="footer">
          <hr>
          <div class="footer__content">
            <p>Want to try SPACEIN for yourself? <br> Create your own community.</p>
            <btn @click="goToGuestRegister" rounded wide large colour="yellow">
              <template #text>
                <b>Sign up to SPACEIN</b>
              </template>
            </btn>
          </div>
        </div>
      </div>
    </div>
    <LoadingIcon v-else expand />
  </div>
</template>

<script>
import LoadingIcon from '@/components/global/loading/LoadingIcon.vue';
import Inputfield from '@/framework/inputfield/Inputfield.vue';
import UserIcon from '@/components/UserIcon.vue'
import JitsiPreview from '@/components/conferencing/JitsiPreview.vue'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  name: 'RoomLobby',
  components: { LoadingIcon, UserIcon, Inputfield, JitsiPreview },
  data: () => ({
    loadingInviteData: true,
    guestName: '',
    enteredPassword: '', //password value,
    showPassword: false,
    agreedToTerms: false,
    passwordOnLoad: null //if there is a password in url params on load
  }),
  computed: {
    ...mapGetters(['showRoomLobby']),
    ...mapGetters('api/auth', {
      ifAuthenticated: 'authenticated', //if registered user
    }),
    ...mapGetters('routing', ['getParamValue']),
    ...mapGetters('current_user', ['fullName', 'email', 'isExternalGuest']),
    ...mapGetters('guest', ['roomName', 'isLeaving', 'isKicked', 'isInvalid', 'invalidReason', 'isPasswordRequired', 'isWaiting', 'isEnteringAfterAuth', 'isRejected', 'areaId', 'roomId', 'confType']),
    ...mapGetters('viewport', ['isDesktop']),
    validInvite() {
      return !!this.roomId && !this.isInvalid
    },
    ifJoining() { //if user is trying to join before clicking join room
      return !this.isLeaving && this.validInvite && !this.isRejected
    },
    invalidMessage() {
      let message = "This invite is not valid. Please request a new one."

      switch (this.invalidReason) {
        case "no_token_provided":
          break
        case "not_found":
          break
        case "space_not_found":
          message = "This Room can't be found. Please try again."
          break
      }

      return message
    },
    ifJitsiRoom() {
      return this.confType === 'spacein' && this.isDesktop ? true : false //don't show jitsi setup if not required for room when entering
    }
  },
  watch: {
    isEnteringAfterAuth(newVal) {
      //join if switches to true
      if(newVal) this.checkIfEnteringAfterAuth()
    },
  },
  methods: {
    ...mapMutations(['guest/ROOM_ID']),
    ...mapActions(['handleJoinRoom']),
    ...mapActions('routing', ['goToHome', 'goToLogin', 'goToGuestRegister', 'getQueryValue']),
    ...mapActions('api/auth', ['logout']),
    ...mapActions('guest', ['handleAccessLobby', 'handleEnterLobby', 'handleCancelJoinLobby', 'handleEnterLobbyWithPassword', 'handleEnterRoomWithPassword']),
    async getRoomIdFromURL() {
      let roomId = this.getParamValue('roomId')
      this['guest/ROOM_ID'](roomId)
      let password = await this.getQueryValue('p')
      if(password) this.passwordOnLoad = password
      return
    },
    async getInviteData() {
      await this.handleAccessLobby(this.roomId)
      this.loadingInviteData = false
      return
    },
    onLogout() {
      // Log out the user, requesting that we then go to the login page and redirect back to this URL
      // so we can keep the same invite token
      const currentUrl = window.location.pathname
      this.logout({
        type: 'loginThenRedirect',
        url: currentUrl
      })
    },
    joinRoom() {
      let name = this.guestName || this.fullName
      if(!name) return console.log('no name')
      if(this.passwordOnLoad) { //password from url params
        this.handleEnterRoomWithPassword([this.passwordOnLoad,name])
        return
      }
      //password
      if(this.isWaiting && this.isPasswordRequired) {
        if(!this.enteredPassword) console.log('password invalid')
        else this.handleEnterLobbyWithPassword([this.enteredPassword])
      } else this.handleEnterLobby(name) //no password
    },
    reJoinRoom() {
      this.handleJoinRoom(this.roomId)
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword
    },
    checkIfEnteringAfterAuth() {
      if(this.isEnteringAfterAuth) {
        this.joinRoom()
      }
    },
    toggleAgreeToTerms() {
      this.agreedToTerms = !this.agreedToTerms
    }
  },
  async mounted() {
    await this.getRoomIdFromURL()
    await this.getInviteData()
    this.checkIfEnteringAfterAuth()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.room-lobby-page {
  height: 100%;
  overflow: auto;
  display: grid;
  min-height: 100vh;
  grid-template-rows: auto 1fr;

  .page-header {
    position: sticky;
    top: 0;
    padding: 1.5rem 2rem;
    padding-bottom: 1.5rem;
    z-index: 99999;
    img {
      max-width: 130px;
      cursor: pointer;
    }
  }
}

.btn {
  justify-self: center;
  padding: 0.7rem 4rem !important;
}

.room-lobby {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  padding: 3rem;

  &__left, &__right {
    display: grid;
    padding: 3rem 3.5rem;
    grid-auto-flow: row;
    max-width: 100%;
    overflow: hidden;
    font-size: 18px;
  }
  &__left {
    img {
      max-width: 100%;
    }
    & .conference-test {
      display: grid;
      align-content: center;
      grid-gap: 2rem;
      color: $grey-3;
      text-align: center;
      font-weight: bold;
    }
  }
  &__right {
    background: $grey-4;
    border-radius: 0.8rem;
    box-shadow: -1px 6px 6px 2px black;
    text-align: center;
    color: $grey-3;
    font-weight: bold;

    & > div {
      display: grid;
      height: 100%;
      align-content: flex-start;
      grid-gap: 3rem;
    }

    & .user-details {
      display: grid;
      justify-items: center;
      font-weight: bold;
      grid-gap: 0.2rem;
      .user-icon {
        height: 100px !important;
        width: 100px !important;
        margin-bottom: 0.5rem;
      }
      .full-name {
        color: white;
        font-size: 24px;
      }
      .email {
        font-size: 20px;
      }
    }

    .logout, .cancel {
      border: 2px solid $grey-3 !important;
    }
    .already-member {
      font-size: 18px;

      & .login {
        color: white;
        border-bottom: 1px solid white;
        cursor: pointer;
      }
    }

    .join-room {
      .header {
        font-size: 24px;
        font-weight: bold;
        .room-name {
          color: white;
          font-size: 32px;
        }
      }
      .loading-icon {
        max-height: 150px;
      }
      .current-user {
        display: grid;
        grid-gap: 3rem;

        .buttons {
          display: flex;
          gap: 0.5rem;
          justify-content: center;
        }
      }
      .waiting, .guest {
        display: grid;
        grid-gap: 2rem;
      }
      .guest {
        margin-top: 3rem;

        .guest {
          margin-top: 0 !important;

          &__terms {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 0.8rem;
            margin-top: -0.5rem;
            margin-bottom: 2rem;

            &:hover {
              cursor: pointer;

              .guest__terms-checkbox {
                position: relative;
              }

              .guest__terms-checkbox::before {
                content: "";
                position: absolute;
                background-color: white;
                width: 100%;
                height: 100%;
                margin: auto;
                border-radius: 100rem;
                opacity: 0.3;
              }
            }

            &-checkbox {
              outline: none;
              width: 15px;
              height: 15px;
              background: none;
              border-radius: 100px;
              border: 2px solid gainsboro;
              display: grid;
              padding: 2px;
              & .checked {
                width: 100%;
                height: 100%;
                margin: auto;
                border-radius: 100rem;
              }
            }
            color: white !important;
            text-align: left !important;
            a {
              text-decoration: none;
            }
            b {
              color: $grey-3 !important;
              cursor: pointer;
              border-bottom: 1px solid gainsboro;
            }
          }
        }
      }
      .waiting {
        .buttons {
          display: grid;
          grid-gap: 1rem;
        }
      }
    }
    .left-room {
      grid-gap: 2rem;
      &:not(.authenticated) {
        align-content: center;
      }

      .user-details {
        padding-bottom: 1rem;
      }

      .btn {
        width: 60%;
      }
      .buttons {
        display: grid;
        grid-gap: 1rem;
      }
    }

    .invalid {
      align-content: center;
      grid-gap: 2rem;
    }

    hr {
      width: 100%;
      border: 1px solid $grey-3;
      opacity: 0.2;
      align-self: flex-start;
    }
    .footer {
      margin-top: 2rem;
      height: calc(100% - 2rem);
      grid-gap: 2rem;
      grid-template-rows: 5px auto;
      align-content: stretch;

      .btn {
        width: 60%;
      }

      &__content {
        display: grid;
        align-self: center;
        align-content: center;
        grid-gap: 2rem;
      }
    }
  }
}

@media only screen and (max-width: $lg) {
  .page-header {
    padding-bottom: 0.5rem !important;
  }
  .room-lobby {
    grid-auto-flow: row !important;
    grid-template-columns: 1fr;
    padding: 1rem;
    gap: 1.2rem;

    &__left, &__right {
      padding: 2rem 1.5rem;
      font-size: 16px;
    }
    &__left {
      &:not(.joining) {
        grid-row: 2/3;
      }
      &.joining {
        padding: 0;

        & .conference-test {
          grid-gap: 1rem;
          padding-top: 1rem;
        }
      }
      & img {
        justify-self: center;
      }
    }

    &__right {
      & > div {
        grid-gap: 2rem;
      }

      & .user-details {
        .user-icon {
          height: 80px !important;
          width: 80px !important;
        }
        .full-name {
          font-size: 20px;
        }
        .email {
          font-size: 16px;
        }
      }
      .already-member {
        font-size: 16px;
      }

      .join-room {
        .header {
          font-size: 18px;
          .room-name {
            font-size: 24px;
          }
        }
        .current-user {
          display: grid;
          grid-gap: 2rem;

          .buttons {
            flex-direction: column-reverse;
            gap: 1rem;
          }
        }
        .guest {
          margin-top: 0 !important;
        }
      }
      .left-room {
        grid-gap: 1.2rem;

        .btn {
          width: 100%;
        }
        .buttons {
          margin-top: 1rem;
        }
      }
      .footer {
        grid-gap: 1.5rem;
        .btn {
          width: 100%;
        }

        &__content {
          grid-gap: 1.2rem;
        }
      }
    }
  }
}
</style>
