var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "jitsi-iframe", attrs: { id: "jitsi" } },
    [_vm.loading ? _c("LoadingIcon", { attrs: { expand: "" } }) : _vm._e()],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }