<template>
  <div class="container">
  <div class="header">
		<div class="header-content">
			<img @click="close" class="header-content__close" src="@/assets/sidebar-icons/close-arrow.svg" alt="Back to settings"/>
			<h1 class="header-content__title">Customise Appearance</h1>
		</div>
		<slot/>
	</div>
    <div class="container__settings">
      <div class="forms">
        <form class="settings-form">
          <div class="header--desktop">
            <h1>Customise Appearance</h1>
          </div>
          <h3 class="settings-form__header">Naming</h3>
          <TextInput class="settings-form__item"
            label="Space"
            v-model="areaName"
          />
          <TextInput class="settings-form__item"
            label="Company Name (Optional)"
            v-model="companyName"
          />
          <TextInput class="settings-form__item"
            label="Lobby"
            v-model="lobbyName"
          />
          <TextInput class="settings-form__item"
            label="Lounge"
            v-model="loungeName"
          />
        </form>
        <form class="settings-form">
          <h3 class="settings-form__header">Information Panel</h3>
           <TextInput class="settings-form__item"
            multiline
            label="Add or change the introduction title"
            placeholder="Type Space introduction title here"
            v-model="areaHeading"
          />
          <TextInput class="settings-form__item"
            multiline
            label="Add or change the description"
            placeholder="Type Space description here"
            v-model="areaDescription"
          />
        </form>


        <form class="settings-form">
          <div class="dropper settings-form__item">
            <ImageUpload
              @imageUploaded="(u) => newImageUploaded(u, 'background')"
              @remove="() => removeImage('background')"
              :imageURL="this.areaBanner"
              imageType="spaceBannerImage"
              previewType="full"
            >
              <template #header>
                Space background image
              </template>
              <template #body>
                <i>Disabled on mobile</i> <br/>
                jpg, png or gif
                <br/><br/>
                Recommended size: 1800x2600px, max 2MB
              </template>
            </ImageUpload>
          </div>
          <div class="dropper settings-form__item">
            <ImageUpload
              @imageUploaded="(u) => newImageUploaded(u, 'logo')"
              @remove="() => removeImage('logo')"
              :imageURL="this.areaLogo"
              imageType="spaceLogoImage"
              previewType="normal"
            >
              <template #header>
                Company logo
              </template>
              <template #body>
                Jpg, png or gif
              </template>
            </ImageUpload>
          </div>
          <div class="dropper settings-form__item">
            <ImageUpload
              @imageUploaded="(u) => newImageUploaded(u, 'coverImage')"
              @remove="() => removeImage('coverImage')"
              :imageURL="this.areaCoverImage"
              imageType="spaceCoverImage"
              previewType="normal"
            >
              <template #header>
                Space collection image
              </template>
              <template #body>
                Jpg, png or gif
                <br/><br/>
                Recommended size: 630x360px
              </template>
            </ImageUpload>
          </div>
        </form>

        <form class="settings-form">
          <h3 class="settings-form__header">Colour</h3>
            <div class="colours">
              <ColorPicker
              v-model="colours.primary"
              label="Space Background Colour"
              />
              <ColorPicker
              v-model="colours.temporary"
              label="Default Temporary Room"
              />
              <ColorPicker
              v-model="colours.fixed"
              label="Default Fixed Room"
              />
              <ColorPicker
              v-model="colours.livestream"
              label="Default Live Stream"
              />
              <ColorPicker
              v-model="colours.link"
              label="Default Link Room"
              />
              <ColorPicker
              v-model="colours.hub"
              label="Lobby"
              />
              <ColorPicker
              v-model="colours.lounge"
              label="Lounge"
              />
            </div>
        </form>

        <FooterButtons>
          <btn v-if="isMobile" colour="ghost" :disabled="!dirty" @onClick="close">
            <template #text>
              <b>Cancel</b>
            </template>
          </btn>
          <btn :disabled="!dirty" :xlarge="!isMobile" colour="green"  @onClick="submit">
            <template #text>
              <b>{{working ? "Saving..." : "Save Changes"}}</b>
            </template>
          </btn>
        </FooterButtons>
      </div>

      <div class="preview">
        <h3 class="preview__header">Space preview</h3>
        <h4 class="preview__subheader">(Not the actual Rooms and Space)</h4>
        <div ref="preview" class="settings-view settings-form__item">
          <div class="rooms" :style="previewStyle">
          <img v-if="areaBanner && areaBanner != ''" :src="areaBanner" class="rooms__background"/>
            <PreviewRoom roomType="create_space" :r="40" :x="390" :y="290"/>
            <PreviewRoom
              roomType="hub"
              :name="lobbyName"
              :BGcolor="this.colours.hub"
              :areaTitle="areaName"
              :areaLogo="areaLogo"
              :online="50"
              :r="120" :x="220" :y="265"
            />
            <PreviewRoom
              :name="loungeName"
              :BGcolor="this.colours.lounge"
              :online="50"
              :r="100" :x="410" :y="440"
            />
            <PreviewRoom
              name="Live Stream"
              :BGcolor="this.colours.livestream"
              :online="20"
              :r="70" :x="350" :y="105"
            />
            <PreviewRoom
              name="Link"
              :BGcolor="this.colours.link"
              :online="10"
              :r="70" :x="220" :y="460"
            />
            <PreviewRoom
              name="Fixed Room"
              :BGcolor="this.colours.fixed"
              :online="10"
              :r="70" :x="470" :y="200"
            />
            <PreviewRoom
              name="Temporary Room"
              :BGcolor="this.colours.temporary"
              :online="10"
              :r="70" :x="560" :y="325"
            />
          </div>
        </div>

        <div class="settings-form__item">
            <h3 class="preview__header">Your collection of Spaces Preview</h3>
            <h4 class="preview__subheader">(Not the actual Space)</h4>
            <SpaceListing @click.prevent="null" class="preview-listing" :space="previewSpace"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import * as d3 from 'd3';
import { mapGetters, mapActions } from 'vuex';

import ColorPicker from '@/components/admin/ColorPicker';
import ImageUpload from '@/components/admin/ImageUpload';
import PreviewRoom from '@/components/space_overview/PreviewRoom.vue';
import SpaceListing from '@/components/home_page/SpaceListing.vue';
import { TextInput, FooterButtons } from '@/framework'
import { processMediaURL } from '@/functions/utility.js';
import FormChangeWatcherMixin from "@/plugins/mixins/formChangeWatcher.js";
import { convertFromServerColourScheme, convertToServerColourScheme } from "@/functions/colourSchemes.js"

export default {
  name: 'SpaceSettings',
  components: {
    ImageUpload,
    TextInput,
    ColorPicker,
    PreviewRoom,
    SpaceListing,
    FooterButtons
  },
  mixins: [ FormChangeWatcherMixin ],
  mounted() {
    this.calculatePreviewScale()

    window.addEventListener('resize', this.calculatePreviewScale)
  },
  unmounted() {
    window.removeEventListener('resize', this.calculatePreviewScale)
  },
  data: ({$store:{getters:{
    getCurrentAreaData: areaData,
    getCurrentAreaInfo: areaInfo,
    getRoomData,
    getCurrentAreaBanner,
    getCurrentAreaCover,
    getCurrentAreaHubId : hubId,
    getCurrentAreaLoungeId : loungeId
  }}}) => ({
    areaName: areaData.name,
    companyName: areaData.companyName,
    areaLogo: areaData.logo,
    areaHeading: areaInfo.heading,
    areaDescription: areaInfo.description,
    areaBanner: getCurrentAreaBanner,
    areaCoverImage: getCurrentAreaCover,

    colourSchemeId: areaData.colourScheme.id,

    // Map out the colour scheme from the server
    colours: convertFromServerColourScheme(areaData.colourScheme),

    hubId,
    loungeId,
    lobbyName: getRoomData(hubId)?.name || '',
    loungeName: getRoomData(loungeId)?.name || '',
    showModal: false,
    areaNameCheck: '',
    uploadActive: false,
    working: false,

    previewInitialWidth: 700,
    previewScale: 1,

    formChangeWatcherSettings: {
      // This tells FormChangeWatcherMixin to watch these inputs
      // so we know when to present the "Save" button
      inputs: [
        "areaName",
        "companyName",
        "areaLogo",
        "areaHeading",
        "areaDescription",
        "areaBanner",
        "colours",
        "lobbyName",
        "loungeName",
        "areaCoverImage"
      ],

      promptOnLeave: true
    },

  }),
  computed: {
    ...mapGetters([
      'getRoomData',
      'getCurrentAreaData',
      'getCurrentAreaInfo',
      'getIfAreaBackgroundIsLightOrDark',
      'getCurrentAreaBanner',
      'getCurrentAreaCover',
      'getSpaces',
      'sidebar/isOpen',
    ]),
    ...mapGetters( {
      'isMobile': 'viewport/isMobile'
    }),
    previewStyle: function() {
      return {
        '--bg-colour': this.colours.primary,
        'transform': `scale(${this.previewScale})`
      }
    },
    sidebarIsOpen: function() {
      return this['sidebar/isOpen']
    },
    previewSpace: function() {
      return {
        primaryColour: this.colours.primary,
        online: 150,
        title: this.areaName,
        companyName: this.companyName,
        info: {
          heading: this.areaHeading,
          description: this.areaDescription,
          coverImage: this.areaCoverImage,
        },
        logo: this.areaLogo,
      }
    }
  },
  watch: {
    sidebarIsOpen: function() {
      setTimeout(() => {
          this.calculatePreviewScale()
      }, 300) // give it a moment to finish transitioning

    }
  },
  methods: {
    ...mapActions([
      'adminCustomise',
      'sidebar/open'
    ]),
    processMediaURL,
    newImageUploaded(dataUrl, type) {
      this.uploadActive = true;

      switch (type) {
        case 'logo':
          this.areaLogo = dataUrl
          break
        case 'background':
          this.areaBanner = dataUrl
          break
        case 'coverImage':
          this.areaCoverImage = dataUrl
          break
      }
    },
    getRoomColor(color) {
      return this.getIfAreaBackgroundIsLightOrDark(color);
    },
    removeImage(type) {
      this.uploadActive = false

      switch (type) {
        case 'logo':
          this.areaLogo = ''
          break
        case 'background':
          this.areaBanner = ''
          break
        case 'coverImage':
          this.areaCoverImage = ''
          break
      }
    },
    calculatePreviewScale() {
      if (!this.$refs.preview) return

      const currentWidth = this.$refs.preview.clientWidth
      const factor = Math.min(currentWidth / this.previewInitialWidth, 1) // don't allow it to grow above 100%
      this.previewScale = factor
    },

    async close() {
      this.promptIfDirty(() => {
          this['sidebar/open']('settings')
      })
    },

    async submit() {
      if(this.working) return;
      this.working = true;

      await this.adminCustomise([
      {
        name: this.areaName,
        companyName: this.companyName,
        colourScheme: {
          ... convertToServerColourScheme(this.colours),
          id: this.colourSchemeId
        },
        logo: this.areaLogo,
        heading: this.areaHeading,
        description: this.areaDescription,
        banner: this.areaBanner,
        coverImage: this.areaCoverImage
      },
      // Room name updates
      [
        {
          ... this.getRoomData(this.hubId),
          topic: this.lobbyName
        },
        {
          ... this.getRoomData(this.loungeId),
          topic: this.loungeName
        }
      ]
      ]);

      this.working = false;
      this.uploadActive = false;
      this.dirty = false
    }
  }
};
</script>
<style scoped lang="scss">
@import '~@/scss/pages/space_settings.scss';
</style>
