var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "header" },
      [
        _c("div", { staticClass: "header-content" }, [
          _c("img", {
            staticClass: "header-content__close",
            attrs: {
              src: require("@/assets/sidebar-icons/close-arrow.svg"),
              alt: "Back to settings",
            },
            on: { click: _vm.close },
          }),
          _c("h1", { staticClass: "header-content__title" }, [
            _vm._v("Customise Appearance"),
          ]),
        ]),
        _vm._t("default"),
      ],
      2
    ),
    _c("div", { staticClass: "container__settings" }, [
      _c(
        "div",
        { staticClass: "forms" },
        [
          _c(
            "form",
            { staticClass: "settings-form" },
            [
              _vm._m(0),
              _c("h3", { staticClass: "settings-form__header" }, [
                _vm._v("Naming"),
              ]),
              _c("TextInput", {
                staticClass: "settings-form__item",
                attrs: { label: "Space" },
                model: {
                  value: _vm.areaName,
                  callback: function ($$v) {
                    _vm.areaName = $$v
                  },
                  expression: "areaName",
                },
              }),
              _c("TextInput", {
                staticClass: "settings-form__item",
                attrs: { label: "Company Name (Optional)" },
                model: {
                  value: _vm.companyName,
                  callback: function ($$v) {
                    _vm.companyName = $$v
                  },
                  expression: "companyName",
                },
              }),
              _c("TextInput", {
                staticClass: "settings-form__item",
                attrs: { label: "Lobby" },
                model: {
                  value: _vm.lobbyName,
                  callback: function ($$v) {
                    _vm.lobbyName = $$v
                  },
                  expression: "lobbyName",
                },
              }),
              _c("TextInput", {
                staticClass: "settings-form__item",
                attrs: { label: "Lounge" },
                model: {
                  value: _vm.loungeName,
                  callback: function ($$v) {
                    _vm.loungeName = $$v
                  },
                  expression: "loungeName",
                },
              }),
            ],
            1
          ),
          _c(
            "form",
            { staticClass: "settings-form" },
            [
              _c("h3", { staticClass: "settings-form__header" }, [
                _vm._v("Information Panel"),
              ]),
              _c("TextInput", {
                staticClass: "settings-form__item",
                attrs: {
                  multiline: "",
                  label: "Add or change the introduction title",
                  placeholder: "Type Space introduction title here",
                },
                model: {
                  value: _vm.areaHeading,
                  callback: function ($$v) {
                    _vm.areaHeading = $$v
                  },
                  expression: "areaHeading",
                },
              }),
              _c("TextInput", {
                staticClass: "settings-form__item",
                attrs: {
                  multiline: "",
                  label: "Add or change the description",
                  placeholder: "Type Space description here",
                },
                model: {
                  value: _vm.areaDescription,
                  callback: function ($$v) {
                    _vm.areaDescription = $$v
                  },
                  expression: "areaDescription",
                },
              }),
            ],
            1
          ),
          _c("form", { staticClass: "settings-form" }, [
            _c(
              "div",
              { staticClass: "dropper settings-form__item" },
              [
                _c("ImageUpload", {
                  attrs: {
                    imageURL: this.areaBanner,
                    imageType: "spaceBannerImage",
                    previewType: "full",
                  },
                  on: {
                    imageUploaded: (u) => _vm.newImageUploaded(u, "background"),
                    remove: () => _vm.removeImage("background"),
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function () {
                        return [_vm._v(" Space background image ")]
                      },
                      proxy: true,
                    },
                    {
                      key: "body",
                      fn: function () {
                        return [
                          _c("i", [_vm._v("Disabled on mobile")]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" jpg, png or gif "),
                          _c("br"),
                          _c("br"),
                          _vm._v(" Recommended size: 1800x2600px, max 2MB "),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "dropper settings-form__item" },
              [
                _c("ImageUpload", {
                  attrs: {
                    imageURL: this.areaLogo,
                    imageType: "spaceLogoImage",
                    previewType: "normal",
                  },
                  on: {
                    imageUploaded: (u) => _vm.newImageUploaded(u, "logo"),
                    remove: () => _vm.removeImage("logo"),
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function () {
                        return [_vm._v(" Company logo ")]
                      },
                      proxy: true,
                    },
                    {
                      key: "body",
                      fn: function () {
                        return [_vm._v(" Jpg, png or gif ")]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "dropper settings-form__item" },
              [
                _c("ImageUpload", {
                  attrs: {
                    imageURL: this.areaCoverImage,
                    imageType: "spaceCoverImage",
                    previewType: "normal",
                  },
                  on: {
                    imageUploaded: (u) => _vm.newImageUploaded(u, "coverImage"),
                    remove: () => _vm.removeImage("coverImage"),
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function () {
                        return [_vm._v(" Space collection image ")]
                      },
                      proxy: true,
                    },
                    {
                      key: "body",
                      fn: function () {
                        return [
                          _vm._v(" Jpg, png or gif "),
                          _c("br"),
                          _c("br"),
                          _vm._v(" Recommended size: 630x360px "),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
          _c("form", { staticClass: "settings-form" }, [
            _c("h3", { staticClass: "settings-form__header" }, [
              _vm._v("Colour"),
            ]),
            _c(
              "div",
              { staticClass: "colours" },
              [
                _c("ColorPicker", {
                  attrs: { label: "Space Background Colour" },
                  model: {
                    value: _vm.colours.primary,
                    callback: function ($$v) {
                      _vm.$set(_vm.colours, "primary", $$v)
                    },
                    expression: "colours.primary",
                  },
                }),
                _c("ColorPicker", {
                  attrs: { label: "Default Temporary Room" },
                  model: {
                    value: _vm.colours.temporary,
                    callback: function ($$v) {
                      _vm.$set(_vm.colours, "temporary", $$v)
                    },
                    expression: "colours.temporary",
                  },
                }),
                _c("ColorPicker", {
                  attrs: { label: "Default Fixed Room" },
                  model: {
                    value: _vm.colours.fixed,
                    callback: function ($$v) {
                      _vm.$set(_vm.colours, "fixed", $$v)
                    },
                    expression: "colours.fixed",
                  },
                }),
                _c("ColorPicker", {
                  attrs: { label: "Default Live Stream" },
                  model: {
                    value: _vm.colours.livestream,
                    callback: function ($$v) {
                      _vm.$set(_vm.colours, "livestream", $$v)
                    },
                    expression: "colours.livestream",
                  },
                }),
                _c("ColorPicker", {
                  attrs: { label: "Default Link Room" },
                  model: {
                    value: _vm.colours.link,
                    callback: function ($$v) {
                      _vm.$set(_vm.colours, "link", $$v)
                    },
                    expression: "colours.link",
                  },
                }),
                _c("ColorPicker", {
                  attrs: { label: "Lobby" },
                  model: {
                    value: _vm.colours.hub,
                    callback: function ($$v) {
                      _vm.$set(_vm.colours, "hub", $$v)
                    },
                    expression: "colours.hub",
                  },
                }),
                _c("ColorPicker", {
                  attrs: { label: "Lounge" },
                  model: {
                    value: _vm.colours.lounge,
                    callback: function ($$v) {
                      _vm.$set(_vm.colours, "lounge", $$v)
                    },
                    expression: "colours.lounge",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "FooterButtons",
            [
              _vm.isMobile
                ? _c("btn", {
                    attrs: { colour: "ghost", disabled: !_vm.dirty },
                    on: { onClick: _vm.close },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "text",
                          fn: function () {
                            return [_c("b", [_vm._v("Cancel")])]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      4242754131
                    ),
                  })
                : _vm._e(),
              _c("btn", {
                attrs: {
                  disabled: !_vm.dirty,
                  xlarge: !_vm.isMobile,
                  colour: "green",
                },
                on: { onClick: _vm.submit },
                scopedSlots: _vm._u([
                  {
                    key: "text",
                    fn: function () {
                      return [
                        _c("b", [
                          _vm._v(
                            _vm._s(_vm.working ? "Saving..." : "Save Changes")
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "preview" }, [
        _c("h3", { staticClass: "preview__header" }, [_vm._v("Space preview")]),
        _c("h4", { staticClass: "preview__subheader" }, [
          _vm._v("(Not the actual Rooms and Space)"),
        ]),
        _c(
          "div",
          { ref: "preview", staticClass: "settings-view settings-form__item" },
          [
            _c(
              "div",
              { staticClass: "rooms", style: _vm.previewStyle },
              [
                _vm.areaBanner && _vm.areaBanner != ""
                  ? _c("img", {
                      staticClass: "rooms__background",
                      attrs: { src: _vm.areaBanner },
                    })
                  : _vm._e(),
                _c("PreviewRoom", {
                  attrs: { roomType: "create_space", r: 40, x: 390, y: 290 },
                }),
                _c("PreviewRoom", {
                  attrs: {
                    roomType: "hub",
                    name: _vm.lobbyName,
                    BGcolor: this.colours.hub,
                    areaTitle: _vm.areaName,
                    areaLogo: _vm.areaLogo,
                    online: 50,
                    r: 120,
                    x: 220,
                    y: 265,
                  },
                }),
                _c("PreviewRoom", {
                  attrs: {
                    name: _vm.loungeName,
                    BGcolor: this.colours.lounge,
                    online: 50,
                    r: 100,
                    x: 410,
                    y: 440,
                  },
                }),
                _c("PreviewRoom", {
                  attrs: {
                    name: "Live Stream",
                    BGcolor: this.colours.livestream,
                    online: 20,
                    r: 70,
                    x: 350,
                    y: 105,
                  },
                }),
                _c("PreviewRoom", {
                  attrs: {
                    name: "Link",
                    BGcolor: this.colours.link,
                    online: 10,
                    r: 70,
                    x: 220,
                    y: 460,
                  },
                }),
                _c("PreviewRoom", {
                  attrs: {
                    name: "Fixed Room",
                    BGcolor: this.colours.fixed,
                    online: 10,
                    r: 70,
                    x: 470,
                    y: 200,
                  },
                }),
                _c("PreviewRoom", {
                  attrs: {
                    name: "Temporary Room",
                    BGcolor: this.colours.temporary,
                    online: 10,
                    r: 70,
                    x: 560,
                    y: 325,
                  },
                }),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "settings-form__item" },
          [
            _c("h3", { staticClass: "preview__header" }, [
              _vm._v("Your collection of Spaces Preview"),
            ]),
            _c("h4", { staticClass: "preview__subheader" }, [
              _vm._v("(Not the actual Space)"),
            ]),
            _c("SpaceListing", {
              staticClass: "preview-listing",
              attrs: { space: _vm.previewSpace },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return null.apply(null, arguments)
                },
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header--desktop" }, [
      _c("h1", [_vm._v("Customise Appearance")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }