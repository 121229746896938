var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showRoomLobby
    ? _c(
        "div",
        { staticClass: "room-lobby-page bg-mid-black" },
        [
          _c("header", { staticClass: "page-header bg-black" }, [
            _c("img", {
              attrs: {
                src: require("@/assets/spacein_logo_white.svg"),
                alt: "",
              },
              on: { click: _vm.goToHome },
            }),
          ]),
          !_vm.loadingInviteData && !_vm.isEnteringAfterAuth
            ? _c("div", { staticClass: "room-lobby" }, [
                _c(
                  "div",
                  {
                    staticClass: "room-lobby__left",
                    class: { joining: _vm.ifJoining && _vm.ifJitsiRoom },
                  },
                  [
                    _vm.ifJoining && _vm.ifJitsiRoom
                      ? _c(
                          "div",
                          { staticClass: "conference-test" },
                          [_vm._m(0), _c("jitsi-preview")],
                          1
                        )
                      : _c("img", {
                          attrs: {
                            src: require("@/assets/spacein-intro.png"),
                            alt: "about spacein",
                          },
                        }),
                  ]
                ),
                _c("div", { staticClass: "room-lobby__right" }, [
                  !_vm.validInvite
                    ? _c("div", { staticClass: "invalid" }, [
                        _vm._v(" " + _vm._s(_vm.invalidMessage) + " "),
                      ])
                    : _vm.isRejected
                    ? _c("div", { staticClass: "invalid" }, [
                        _vm._v(" You are removed from the lobby "),
                      ])
                    : _vm.isKicked
                    ? _c("div", { staticClass: "invalid" }, [
                        _vm._v(" You are removed from the room "),
                      ])
                    : _vm.ifJoining
                    ? _c("div", { staticClass: "join-room" }, [
                        _c("div", { staticClass: "header" }, [
                          _c("p", [
                            _vm._v(
                              _vm._s(`${_vm.isWaiting ? "Joining..." : "Join"}`)
                            ),
                          ]),
                          _c("h3", { staticClass: "room-name" }, [
                            _vm._v(_vm._s(_vm.roomName)),
                          ]),
                        ]),
                        _vm.isWaiting
                          ? _c(
                              "div",
                              { staticClass: "waiting" },
                              [
                                _c("LoadingIcon"),
                                _c("p", [
                                  _vm._v("Please hold on, the host "),
                                  !_vm.isPasswordRequired ? _c("br") : _vm._e(),
                                  _vm._v(" will let you in soon"),
                                  _vm.isPasswordRequired
                                    ? _c("span", [
                                        _vm._v(", "),
                                        _c("br"),
                                        _vm._v(
                                          "or enter the password to join the Room now."
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                                _vm.isPasswordRequired
                                  ? _c("inputfield", {
                                      attrs: {
                                        large: "",
                                        rounded: "",
                                        placeholder: "Type in Room's password",
                                        customClasses: [
                                          "bg-black",
                                          "text-white",
                                          "text-bold",
                                        ],
                                        type: _vm.showPassword
                                          ? "text"
                                          : "password",
                                      },
                                      on: {
                                        keypress: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          )
                                            return null
                                          return _vm.joinRoom.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "image",
                                            fn: function () {
                                              return [
                                                _vm.showPassword &&
                                                _vm.isWaiting
                                                  ? _c("img", {
                                                      staticClass: "text-white",
                                                      attrs: {
                                                        src: require("@/assets/eye-open.svg"),
                                                        alt: "show password",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.toggleShowPassword,
                                                      },
                                                    })
                                                  : _vm.isWaiting
                                                  ? _c("img", {
                                                      staticClass: "text-white",
                                                      attrs: {
                                                        src: require("@/assets/eye.svg"),
                                                        alt: "show password",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.toggleShowPassword,
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        1889410604
                                      ),
                                      model: {
                                        value: _vm.enteredPassword,
                                        callback: function ($$v) {
                                          _vm.enteredPassword = $$v
                                        },
                                        expression: "enteredPassword",
                                      },
                                    })
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticClass: "buttons" },
                                  [
                                    _vm.isPasswordRequired
                                      ? _c("btn", {
                                          attrs: {
                                            disabled: !_vm.enteredPassword,
                                            rounded: "",
                                            wide: "",
                                            large: "",
                                            colour: "green",
                                          },
                                          on: { click: _vm.joinRoom },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "text",
                                                fn: function () {
                                                  return [
                                                    _c("b", [
                                                      _vm._v("Join Room"),
                                                    ]),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            2143493096
                                          ),
                                        })
                                      : _vm._e(),
                                    _c("btn", {
                                      staticClass: "cancel",
                                      attrs: {
                                        rounded: "",
                                        wide: "",
                                        large: "",
                                        colour: "ghost",
                                      },
                                      on: { click: _vm.handleCancelJoinLobby },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "text",
                                            fn: function () {
                                              return [
                                                _c("b", [_vm._v("Cancel")]),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        4242754131
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : !_vm.ifAuthenticated
                          ? _c(
                              "div",
                              { staticClass: "guest" },
                              [
                                _c("inputfield", {
                                  attrs: {
                                    large: "",
                                    rounded: "",
                                    placeholder: "Type in your name",
                                    customClasses: [
                                      "bg-black",
                                      "text-white",
                                      "text-bold",
                                    ],
                                  },
                                  on: {
                                    keypress: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.joinRoom.apply(null, arguments)
                                    },
                                  },
                                  model: {
                                    value: _vm.guestName,
                                    callback: function ($$v) {
                                      _vm.guestName = $$v
                                    },
                                    expression: "guestName",
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "guest__terms",
                                    on: { click: _vm.toggleAgreeToTerms },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "guest__terms-checkbox" },
                                      [
                                        _vm.agreedToTerms
                                          ? _c("span", {
                                              staticClass: "checked bg-green",
                                            })
                                          : _vm._e(),
                                      ]
                                    ),
                                    _c("small", [
                                      _vm._v(" I agree to SPACEIN "),
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                            },
                                          },
                                        },
                                        [_vm._m(1)]
                                      ),
                                      _vm._v(" and "),
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                            },
                                          },
                                        },
                                        [_vm._m(2)]
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("btn", {
                                  attrs: {
                                    disabled:
                                      !_vm.guestName || !_vm.agreedToTerms,
                                    rounded: "",
                                    wide: "",
                                    large: "",
                                    colour: "green",
                                  },
                                  on: { click: _vm.joinRoom },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "text",
                                        fn: function () {
                                          return [
                                            _c("b", [_vm._v("Join Room")]),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    2143493096
                                  ),
                                }),
                                _c("p", { staticClass: "already-member" }, [
                                  _vm._v("Already a member? "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "login",
                                      on: { click: _vm.goToLogin },
                                    },
                                    [_c("b", [_vm._v("Login")])]
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _c("div", { staticClass: "current-user" }, [
                              _c(
                                "div",
                                { staticClass: "user-details" },
                                [
                                  _c("user-icon", {
                                    attrs: {
                                      currentUser: true,
                                      userStatus: "offline",
                                    },
                                  }),
                                  _c("p", { staticClass: "full-name" }, [
                                    _vm._v(_vm._s(_vm.fullName)),
                                  ]),
                                  _c("p", { staticClass: "email" }, [
                                    _vm._v(_vm._s(_vm.email)),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "buttons" },
                                [
                                  _c("btn", {
                                    staticClass: "logout",
                                    attrs: {
                                      rounded: "",
                                      wide: "",
                                      large: "",
                                      colour: "ghost",
                                    },
                                    on: { click: _vm.onLogout },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "text",
                                          fn: function () {
                                            return [_c("b", [_vm._v("Logout")])]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      1566107743
                                    ),
                                  }),
                                  _c("btn", {
                                    attrs: {
                                      rounded: "",
                                      wide: "",
                                      large: "",
                                      colour: "green",
                                    },
                                    on: { click: _vm.joinRoom },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "text",
                                          fn: function () {
                                            return [
                                              _c("b", [_vm._v("Join Room")]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      2143493096
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ]),
                      ])
                    : _c(
                        "div",
                        {
                          staticClass: "left-room",
                          class: { authenticated: _vm.ifAuthenticated },
                        },
                        [
                          _vm.ifAuthenticated
                            ? _c(
                                "div",
                                { staticClass: "user-details" },
                                [
                                  _c("user-icon", {
                                    attrs: {
                                      currentUser: true,
                                      userStatus: "offline",
                                    },
                                  }),
                                  _c("p", { staticClass: "full-name" }, [
                                    _vm._v(_vm._s(_vm.fullName)),
                                  ]),
                                  _c("p", { staticClass: "email" }, [
                                    _vm._v(_vm._s(_vm.email)),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("p", [_vm._v("You have left the Room.")]),
                          _c(
                            "div",
                            { staticClass: "buttons" },
                            [
                              !_vm.isKicked
                                ? _c("btn", {
                                    attrs: {
                                      rounded: "",
                                      wide: "",
                                      large: "",
                                      colour: "mauve",
                                    },
                                    on: { click: _vm.reJoinRoom },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "text",
                                          fn: function () {
                                            return [_c("b", [_vm._v("Rejoin")])]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      397522176
                                    ),
                                  })
                                : _vm._e(),
                              _vm.ifAuthenticated && !_vm.isExternalGuest
                                ? _c("btn", {
                                    attrs: {
                                      rounded: "",
                                      wide: "",
                                      large: "",
                                      colour: "yellow",
                                    },
                                    on: { click: _vm.goToHome },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "text",
                                          fn: function () {
                                            return [
                                              _c("b", [
                                                _vm._v("Go to my Spaces"),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      2294669829
                                    ),
                                  })
                                : _vm._e(),
                              _vm.ifAuthenticated
                                ? _c("btn", {
                                    staticClass: "logout",
                                    attrs: {
                                      rounded: "",
                                      wide: "",
                                      large: "",
                                      colour: "ghost",
                                    },
                                    on: { click: _vm.onLogout },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "text",
                                          fn: function () {
                                            return [_c("b", [_vm._v("Logout")])]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      1566107743
                                    ),
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                  (!_vm.ifJoining && _vm.isExternalGuest) ||
                  !_vm.validInvite ||
                  _vm.isRejected
                    ? _c("div", { staticClass: "footer" }, [
                        _c("hr"),
                        _c(
                          "div",
                          { staticClass: "footer__content" },
                          [
                            _vm._m(3),
                            _c("btn", {
                              attrs: {
                                rounded: "",
                                wide: "",
                                large: "",
                                colour: "yellow",
                              },
                              on: { click: _vm.goToGuestRegister },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "text",
                                    fn: function () {
                                      return [
                                        _c("b", [_vm._v("Sign up to SPACEIN")]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                2651087099
                              ),
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]),
              ])
            : _c("LoadingIcon", { attrs: { expand: "" } }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "info" }, [
      _c("p", [_vm._v("Allow SPACEIN to use your camera and microphone.")]),
      _c("p", { staticClass: "text-white" }, [
        _vm._v("Preview your audio and video."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "text-white",
        attrs: {
          target: "_blank",
          href: "https://spacein.co.uk/terms-and-conditions",
        },
      },
      [_c("b", [_vm._v("Terms & Conditions")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "text-white",
        attrs: {
          target: "_blank",
          href: "https://spacein.co.uk/privacy-policy",
        },
      },
      [_c("b", [_vm._v("Privacy Policy.")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("Want to try SPACEIN for yourself? "),
      _c("br"),
      _vm._v(" Create your own community."),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }